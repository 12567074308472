<template>
  <app-overlay loading-variable="modalOverlay">
    <db-searchable-table v-model="filters" @changed="getData" class="border mb-50" :items="items.data" :total-rows="items.total" :fields="fields" searchable>
      <template #modalbutton>
        <app-button @click="$showAppModalSidebar('Dosya Yükle', EmployeeFileUploadForm)" text="Dosya Yükle" icon="UploadIcon" size="md" />
      </template>
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item @click="downloadFile(item)">
            <feather-icon icon="DownloadIcon" class="mr-50" />
            <span>İndir</span>
          </b-dropdown-item>
          <b-dropdown-item @click="deleteFile(item)">
            <feather-icon icon="TrashIcon" class="mr-50" />
            <span>Sil</span>
          </b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import EmployeeFileUploadForm from "./EmployeeFileUploadForm.vue";

export default {
  data() {
    return {
      filters: {},
      EmployeeFileUploadForm,
    };
  },
  computed: {
    items() {
      return this.$store.getters.companyEmployeeFiles;
    },
    employee() {
      return this.$store.getters.appModalItem;
    },
    fields() {
      return [
        { key: "customName", label: "Dosya Adı" },
        { key: "createdAt", label: "Oluşturulma Tarihi", formatDateTime: true },
        { key: "actions", label: "İşlemler" },
      ];
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
  },
  methods: {
    getData() {
      this.$store.dispatch("getEmployeeFiles", {
        query: {
          ...this.filters,
          searchFields: this.filters.search ? JSON.stringify(["customName"]) : null,
          employee: this.employee._id,
        },
      });
    },
    downloadFile(item) {
      this.$axios
        .get(`/employees/employee-files/download/${item?._id}`, { responseType: "blob" })
        .then((result) => {
          const blob = new Blob([result.data], { type: result.headers["content-type"] });
          window.open(window.URL.createObjectURL(blob), "_blank");
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.message ?? null });
        });
    },
    deleteFile(item) {
      this.$confirm({ message: "Dosyayı silmek istediğinize emin misiniz?" }, () => {
        this.$store.dispatch("deleteEmployeeFile", { id: item._id });
      });
    },
  },
  mounted() {
    this.getData();
  },
  destroyed() {
    this.$store.commit("setCompanyEmployeeFiles", []);
  },
};
</script>
