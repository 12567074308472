<template>
  <app-overlay>
    <db-searchable-table
      v-model="filters"
      @changed="getAssignedEmployees"
      @row-clicked="showDetails"
      :class="windowWidth < 767 ? 'mb-0' : 'border mb-50'"
      :items="employees"
      :fields="fields"
      :total-rows="total"
      hover
      searchable
    >
      <template #modalbutton>
        <b-dropdown class="flex-1-md" split text="Primary" variant="primary" @click="$showAppSidebar('Çalışan Ekle', EmployeeForm, setQuery())">
          <template #button-content>
            <span class="d-flex align-items-center fs-14 gap-4 no-wrap">Çalışan Ekle </span>
          </template>
          <b-dropdown-item @click="showCreateManyForm">
            <span class="d-flex align-items-center gap-10"><feather-icon icon="UploadIcon" size="13" /> Toplu Ekle </span>
          </b-dropdown-item>
        </b-dropdown>
        <app-button
          text="Destek Elemanları"
          :size="windowWidth < 767 ? 'sm' : 'md'"
          icon="UsersIcon"
          class="flex-1-md"
          @click="$showAppModal({ title: 'Destek Elemanları', component: supportedEmployees, size: 'custom', centered: false })"
        />
        <app-button
          class="flex-1-md"
          text="Çalışan Temsilcisi"
          icon="UserIcon"
          :size="windowWidth < 767 ? 'sm' : 'md'"
          @click="$showAppModal({ title: 'Çalışan Temsilcisi Ekle', component: EmployeeRepresentative, size: 'custom', centered: false })"
        />
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import supportedEmployees from "./supportedEmployees/SupportedEmployeesList.vue";
import EmployeeRepresentative from "./EmployeeRepresentative.vue";
import EmployeeForm from "./singleEmployeeForm.vue";
import CreateFromTemplateForm from "./CreateFromTemplateForm.vue";
import EmployeeDetailModal from "./details/EmployeeDetailModal.vue";

export default {
  data() {
    return {
      filters: {},
      supportedEmployees,
      EmployeeRepresentative,
      EmployeeForm,
    };
  },
  computed: {
    fields() {
      if (this.windowWidth < 767) {
        return [
          { key: "fullname", label: "Ad Soyad", sortable: true },
          { key: "duty", label: "Görevi", sortable: true },
        ];
      }
      return [
        ...(this.selectedBranch ? [] : [{ key: "branch.name", label: "Sicil" }]),
        { key: "fullname", label: "Ad Soyad", sortable: true },
        { key: "phone", label: "Telefon", sortable: true },
        { key: "duty", label: "Görevi", sortable: true },
      ];
    },
    employees() {
      return this.$store.getters.employees?.data;
    },
    total() {
      return this.$store.getters.employees?.total;
    },
    company() {
      return this.$store.getters?.selectedCompany?._id;
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
  },
  methods: {
    setQuery() {
      return {
        ...this.filters,
        company: this?.company,
      };
    },
    getAssignedEmployees() {
      this.$store.dispatch("getEmployees", {
        query: this.setQuery(),
      });
    },
    showCreateManyForm() {
      this.$showAppSidebar("Toplu Ekle", CreateFromTemplateForm, this.setQuery());
    },
    showDetails(employee) {
      this.$showAppModal({
        title: "Çalışan Detayları",
        component: EmployeeDetailModal,
        size: "custom",
        centered: false,
        item: { ...employee },
      });
    },
  },
};
</script>

<style></style>
