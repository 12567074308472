<template>
  <custom-collapse :collapses="collapses">
    <template
      v-for="(item, key) in collapses"
      :slot="item.key"
    >
      <supported-employees-table
        :key="key"
        :employee-type="item.key"
      />
    </template>
  </custom-collapse>
</template>

<script>
import SupportedEmployeesTable from './SupportedEmployeesTable.vue'

export default {
  components: { SupportedEmployeesTable },
  data() {
    return {
      collapses: [
        {
          key: 'rescueTeam',
          title: 'Arama Kurtarma Ekibi',
        },
        {
          key: 'fireTeam',
          title: 'Yangın Söndürme Ekibi',
        },
        {
          key: 'firstAidTeam',
          title: 'İlk Yardım Ekibi',
        },
        {
          key: 'protectionTeam',
          title: 'Koruma Ekibi',
        },
      ],
    }
  },
}
</script>

<style></style>
