<template>
  <app-overlay loading-variable="modalOverlay">
    <searchable-table :fields="fields" class="border mb-1" :items="supportedEmployees" />
  </app-overlay>
</template>

<script>
export default {
  props: {
    employeeType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "employee.fullname", label: "Ad Soyad", sortable: true },
        { key: "branch.name", label: "Sicil", sortable: true },
        {
          key: "createdAt",
          label: "Eklenme Tarihi",
          sortable: true,
          formatDateTime: true,
        },
      ],
    };
  },
  computed: {
    supportedEmployees() {
      return this.$store.getters.supportTeams(this.employeeType);
    },
    company() {
      return this.$store.getters?.selectedCompany?._id;
    },
  },
  mounted() {
    this.getSupportTeams();
  },
  destroyed() {
    this.$store.commit("setSupportTeams", []);
  },
  methods: {
    getSupportTeams() {
      if (this.company) {
        this.$axios
          .get("/employees/support-team/list", { params: { company: this.company }, loading: "modal" })
          .then((res) => {
            this.$store.commit("setSupportTeams", res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style></style>
